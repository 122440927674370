<script setup>
import { defineEmits, defineProps } from 'vue';
import { useFiltrosAmbiente } from '../../composables/useFiltrosAmbiente';

const emit = defineEmits(['loadAmbiente', 'update:filtrosExtras']);
const props = defineProps({
    filtrosExtras: {
        type: Object
    }
});
const { _filtrosExtras, filtrar, removerFiltros } = useFiltrosAmbiente(props, emit);
</script>
<template>
    <div class="flex flex-row align-items-center">
        <div class="flex grid align-items-center w-full">
            <div class="field col-12 md:col-3">
                <label for="setor">Pesquisar por setor</label>
                <InputText @keyup.enter="filtrar" id="setor" v-model="_filtrosExtras.name" class="w-full" />
            </div>

            <div class="field col-12 md:col-3">
                <Button label="Remover filtros" icon="pi pi-filter-slash" class="p-button-link" @click="removerFiltros" />
                <Button label="Filtrar" icon="pi pi-filter" class="p-button-outlined" @click="filtrar" />
            </div>
        </div>
    </div>
</template>
