import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export function useFiltrosAmbiente(props, emit) {
    const router = useRouter();
    const store = useStore();

    const _filtrosExtras = computed({
        get() {
            return props.filtrosExtras;
        },
        set(value) {
            emit('update:filtrosExtras', value);
        }
    });

    async function filtrar() {
        const { name } = _filtrosExtras.value;

        if (name?.length > 0 && name?.length < 3) return;

        await atualizarQueryParams();
        emit('loadAmbiente');
    }

    async function atualizarQueryParams() {
        const { name } = _filtrosExtras.value;
        store.dispatch('setQueryParamsTelaAmbienteGro', {
            name: name ? JSON.stringify(name) : undefined
        });
        await router.replace({
            query: {
                name: name ? JSON.stringify(name) : undefined
            }
        });
    }

    async function removerFiltros() {
        _filtrosExtras.value = {};
        await router.replace();
        emit('loadAmbiente', true);
    }

    return {
        _filtrosExtras,
        filtrar,
        removerFiltros
    };
}
