<script setup>
import { ref, defineExpose, defineEmits } from 'vue';

import { getClientSesmt } from '../../../../services/http-sesmt';

import { useAlert } from '../../../../composables/useAlert.js';

const emit = defineEmits(['update:visible']);

const { alertError, alertSucess, alertErrorData } = useAlert();

const isOpen = ref(false);
const isLoading = ref(false);
const tipoDescricao = ref('DESCRICAO_TECNICA');
const form = ref();
const descricaoAmbiente = ref();
const descricao = ref();
const loadingSalvar = ref(false);

const load = async (id) => {
    try {
        isLoading.value = false;
        const { data } = await getClientSesmt().get(`aplicacao-pgr-elaboracao/aplicacao-respostas/origem-id/${id}/origem/AMBIENTE`);

        form.value = data?.aplicacaoResposta[0];

        if (data?.descricaoAmbiente[0] !== null) {
            descricaoAmbiente.value = data?.descricaoAmbiente[0];
            descricao.value = data?.descricaoAmbiente?.[0]?.descricao;
        }
    } catch (error) {
        const { message } = error?.response?.data;
        alertError(`Erro ao listar! ${message}`);
    } finally {
        isLoading.value = false;
    }
};

const salvar = async () => {
    try {
        loadingSalvar.value = true;
        if (descricaoAmbiente.value?.id) {
            await getClientSesmt().post('aplicacao-pgr-elaboracao/aplicacao-questionario-ambiente/descricao-por-setor', {
                id: descricaoAmbiente.value.id,
                aplicacaoRespostaId: form.value.id,
                descricao: descricao.value
            });
        } else {
            await getClientSesmt().post('aplicacao-pgr-elaboracao/aplicacao-questionario-ambiente/descricao-por-setor', {
                aplicacaoRespostaId: form.value.id,
                descricao: descricao.value
            });
        }

        closeDialog();
        alertSucess('Descrição salva com sucesso.');
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            closeDialog();
            return;
        }

        alertErrorData(error, 'Não foi possível salvar descrição, por favor tente novamente.');
    } finally {
        loadingSalvar.value = false;
    }
};

const openDialog = async (id) => {
    await load(id);
    isOpen.value = true;
};

const closeDialog = () => {
    emit('update:visible', false);
    tipoDescricao.value = 'DESCRICAO_TECNICA';
    form.value = null;
    descricaoAmbiente.value = null;
    descricaoAmbiente.value = null;
    isOpen.value = false;
};

defineExpose({
    openDialog
});
</script>
<template>
    <Dialog
        v-model:visible="isOpen"
        :style="{ width: '500px' }"
        header="Descrição detalhada"
        @hide="closeDialog"
        :draggable="false"
        modal
        :closable="!isLoading"
    >
        <div v-if="!isLoading" class="flex flex-column gap-2 w-full line-height-3">
            <span class="font-semibold line-height-3">Escolha uma das opções como padrão</span>
            <div class="flex align-items-center mb-3">
                <RadioButton v-model="tipoDescricao" inputId="value" name="value" value="DERCRICAO" />
                <label class="ml-2 font-semibold">Descrição escrita por você:</label>
            </div>
            <div class="flex flex-column field w-full">
                <label for="elaboradorPGR">Digite uma descrição detalhada (de sua preferência)</label>
                <InputText
                    v-model="descricao"
                    :disabled="tipoDescricao === 'DESCRICAO_TECNICA'"
                    class="w-full"
                    placeholder="Fornecida pelo Técnico de segurança do trabalhos"
                />
            </div>
            <div class="flex align-items-center mb-3">
                <RadioButton v-model="tipoDescricao" inputId="value" name="value" value="DESCRICAO_TECNICA" />
                <label class="ml-2 font-semibold">Descrição, fornecida pelo Técnico de segurança do trabalho:</label>
            </div>
            <div class="flex w-full flex-wrap justify-content-start">
                <label>{{ form?.resposta }}</label>
            </div>
        </div>
        <template #footer>
            <div class="flex aling-itens-end pt-4 justify-content-end">
                <Button class="button-link" @click="closeDialog" text label="Cancelar" />
                <Button class="button-salvar p-button" :loading="loadingSalvar" type="button" label="Salvar" @click="salvar" />
            </div>
        </template>
    </Dialog>
</template>
