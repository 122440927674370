<script setup>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useAlert } from '../../composables/useAlert';
import StatusGrid from './components/StatusGrid';
import SesmtService from '../../services/SesmtService';
import StatusGroQuestionariosElaboracaoEnum from '../../enums/StatusGroQuestionariosElaboracao';
import HeaderPanel from './components/HeaderPanel';
import FiltroAmbiente from './components/grid-ambiente/FiltroAmbiente.vue';
import DialogQuestionarioAmbiente from './components/grid-ambiente/DialogQuestionarioAmbiente.vue';
import DialogDescricaoDetalhada from './components/grid-ambiente/DialogDescricaoDetalhada.vue';

import { getClientSesmt } from '../../services/http-sesmt';

const { currentRoute, replace } = useRouter();
const { alertError } = useAlert();

const store = useStore();
const queryParams = ref({});
const customer = ref({});
const loading = ref(false);
const filtrosDoComponente = ref({});
const records = ref([]);
const recordsPerPage = ref(10);
const totalRecords = ref(0);
const currentSortField = ref(null);
const currentSortOrder = ref(null);
const sort = ref(null);
const page = ref(1);
const ambienteColunas = [
    {
        field: 'setor',
        header: 'Setor',
        sortable: true,
        sortField: 'codigoExterno'
    },
    {
        field: 'statusGro',
        header: 'Status',
        sortable: true,
        sortField: 'status_gro'
    },
    // {
    //     field: 'descricao',
    //     header: 'Descrição',
    //     sortable: true,
    //     sortField: 'name'
    // },
    {
        field: 'acoes',
        sortable: false,
        style: 'width: 55px'
    }
];
const statusQuestionariosElaboracaoOpcoes = ref([]);
const styleMapStatus = {
    [StatusGroQuestionariosElaboracaoEnum.PENDENTE]: {
        backgroundColor: '#F6DFC3',
        color: '#653E11'
    },
    [StatusGroQuestionariosElaboracaoEnum.AGUARDANDO_RETORNO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroQuestionariosElaboracaoEnum.VALIDADO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroQuestionariosElaboracaoEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

const dialogQuestionarioAmbiente = ref(null);
const dialogDescricaoDetalhada = ref(null);

const aplicarFiltros = async (removerFiltros = false) => {
    if (removerFiltros) {
        filtrosDoComponente.value = {};
        atualizarQueryParamsStore({});
    }

    await load();
};

const atualizarQueryParams = async () => {
    const { name } = filtrosDoComponente.value;

    atualizarQueryParamsStore({
        name: name ? JSON.stringify(name) : undefined
    });

    await replace({
        query: {
            name: name ? JSON.stringify(name) : undefined
        }
    });
};

const atualizarQueryParamsStore = (params) => store.dispatch('setQueryParamsTelaAmbienteGro', params);

//const openDialogDescricaoDetalhada = (id) => dialogDescricaoDetalhada.value?.openDialog(id);

const openDialogQuestionarioAmbiente = (id) => dialogQuestionarioAmbiente.value?.openDialog(id);

const getCustomer = async (customerId) => {
    const { data } = await new SesmtService('/customers').findById(customerId);
    customer.value = data;
};

const getStatusQuestionariosElaboracaoOpcoes = () => {
    statusQuestionariosElaboracaoOpcoes.value = Object.keys(StatusGroQuestionariosElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroQuestionariosElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusGroQuestionariosElaboracaoEnum[type]]
        };
    });
};

const getStatusComTodasAsConfigs = (statusGro) => statusQuestionariosElaboracaoOpcoes.value.find((p) => p.label === statusGro);

const load = async () => {
    try {
        loading.value = true;
        const id = currentRoute.value.params.visitaTecnicaId;
        const { data: dataBaseSetores } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/aplicacao-questionario/find-by-inventario-risco-visita-tecnica/${id}/origem/AMBIENTE`,
            {
                params: {
                    limit: recordsPerPage.value,
                    page: page.value,
                    sort: sort.value,
                    filtrosExtras: {
                        name: filtrosDoComponente.value?.name
                    }
                }
            }
        );
        records.value = dataBaseSetores;
    } catch (error) {
        const { message } = error?.response?.data;
        alertError(`Erro ao listar! ${message}`);
    } finally {
        loading.value = false;
    }
};

const onPage = async (event) => {
    page.value = event.page + 1;
    recordsPerPage.value = event.rows;
    await load();
};

const onSort = async (event) => {
    currentSortField.value = event.sortField;
    currentSortOrder.value = event.sortOrder;
    const field = event.sortField
        ?.split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()
        ? `${event.sortField
              ?.split(/(?=[A-Z])/)
              .join('_')
              .toLowerCase()}`
        : '';
    const order = event.sortOrder == 1 ? `ASC` : 'DESC';
    if (field) {
        sort.value = {
            [field]: order
        };
    } else {
        sort.value = null;
    }

    await load();
};

const init = async () => {
    const routeQueryParams = currentRoute.value?.query;
    console.log('routeQueryParams', routeQueryParams);
    queryParams.value = Object.keys(routeQueryParams).length ? routeQueryParams : store.getters.queryParamsTelaAmbienteGro;
    console.log('queryParams', queryParams.value);
    if (Object.keys(queryParams.value).length) {
        await atualizarQueryParams();
    } else {
        filtrosDoComponente.value = {
            name: queryParams.value?.name
        };

        await atualizarQueryParams();
    }
    try {
        const customerId = currentRoute.value?.params?.id ? Number(currentRoute.value?.params?.id) : 0;
        await getCustomer(customerId);
        await atualizarQueryParams();
        getStatusQuestionariosElaboracaoOpcoes();
        await load();
    } catch (error) {
        alertError(error, 'Ocorreu um problema');
    }
};

onBeforeMount(init);
</script>
<template>
    <div class="card">
        <HeaderPanel tooltip="Listagem de ambiente" :title="'Ambiente'" nomeTelaDoManual="customer-ambiente-gro">
            <template #extraContent>
                <div class="flex flex-row justify-content-center align-items-center gap-2 identificacao-customer">
                    <i class="pi pi-building text-4xl"></i>
                    <div class="flex flex-column">
                        <span class="font-semibold line-height-3 cursor-pointer nome-cliente" v-tooltip.bottom="customer?.name">{{
                            customer?.name
                        }}</span>
                        <span class="">Cód. SOC - {{ customer?.code }}</span>
                    </div>
                </div>
            </template>
        </HeaderPanel>
        <FiltroAmbiente v-model:filtrosExtras="filtrosDoComponente" @loadAmbiente="aplicarFiltros" />

        <DataTable
            dataKey="id"
            :value="records"
            lazy
            :loading="loading"
            loadingIcon="pi pi-spinner"
            paginator
            :rows="recordsPerPage"
            :totalRecords="totalRecords"
            :rowsPerPageOptions="[10, 50, 100]"
            tableStyle="min-width: 50rem; height: 100%"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            :sortField="currentSortField"
            :sortOrder="currentSortOrder"
            removableSort
            @page="onPage"
            @sort="onSort"
        >
            <template #empty> Nenhum registro encontrado.</template>
            <template #loading>
                <AppLoadingWrapper />
            </template>
            <Column
                v-for="col of ambienteColunas"
                :field="col.field"
                :key="col.field"
                :style="col.style"
                :sortable="col.sortable"
                :sortField="col.sortField"
            >
                <template #header>
                    <div class="font-semibold">{{ col.header }}</div>
                </template>

                <template #body="{ data }">
                    <!-- Aguardando direcionamento,  como vai ser exibido a descrição detalhada PO/Desing -->

                    <!-- <template v-if="col.field === 'descricao'">
                        <div class="block-descricao">
                            <span>{{ data.respostasConcatenadas }}</span>
                            <Button @click="openDialogDescricaoDetalhada(data.id)" label="Ver descrição completa" class="p-button-link button-link" />
                        </div>
                    </template> -->

                    <template v-if="col.field === 'statusGro'">
                        <StatusGrid v-if="data.statusGro" :status="getStatusComTodasAsConfigs(data.statusGro)" />
                    </template>

                    <template v-else-if="col.field === 'acoes'">
                        <Button label="Acessar" class="p-button-link" @click="openDialogQuestionarioAmbiente(data.id)" />
                    </template>

                    <template v-else>
                        <span>{{ data.setor.name }}</span>
                    </template>
                </template>
            </Column>
        </DataTable>
        <DialogDescricaoDetalhada @update:visible="load()" ref="dialogDescricaoDetalhada" />
        <DialogQuestionarioAmbiente @update:visible="load()" ref="dialogQuestionarioAmbiente" />
    </div>
</template>
<style scoped lang="scss">
.identificacao-customer {
    padding: 1rem;
    border: solid #ededed 1px;
    border-radius: 4px;
}

.nome-cliente {
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.block-descricao {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.button-link {
    padding: 0;
    margin: 10px 0 0 0;
}
</style>
